import { v4 as uuid } from "uuid";

export const actionTypes = {
  updateInputField: "updateInputField",
  updateCheckbox: "updateCheckbox",
  updatePhaseInput: "updatePhaseInput",
  updatePfs: "updatePfs",
  updatePhaseCodeField: "updatePhaseCodeField",
  updatePhaseCodeFieldObject: "updatePhaseCodeFieldObject",
  setParForm: "setParForm",
  clearPhasecode: "clearPhasecode",
  removePhaseCode: "removePhaseCode",
  setWeatherInputs: "setWeatherInputs",
  deleteIdb: "deleteIdb",
  updateIdb: "updateIdb",
  addIdb: "addIdb",
  addDeprecatedIdb: "addDeprecatedIdb",
  deleteSubcontractor: "deleteSubcontractor",
  updateSubcontractor: "updateSubcontractor",
  addSubcontractor: "addSubcontractor",
  deleteMaterial: "deleteMaterial",
  updateMaterial: "updateMaterial",
  addMaterial: "addMaterial",
  addPhaseCode: "addPhaseCode",
  setPhaseCode: "setPhaseCode",
  deletePhaseRow: "deletePhaseRow",
  addRecord: "addRecord",
  addRecords: "addRecords",
  removeRecord: "removeRecord",
  removeFromAllPhases: "removeFromAllPhases",
  autofillPhases: "autofillPhases",
  addHrvc: "addHrvc",
  deleteHrvc: "deleteHrvc",
  editHrvc: "editHrvc",
  initializeCheckboxes: "initializeCheckboxes",
};

export const initializeCheckboxes = (parData) => {
  const checkboxes = {
    issues: parData.idb?.[0]?.issueNumber !== "0" && parData.idb?.length > 0,
    injuries: parData.injuries === "Nothing to Report." ? false : true,
    material: parData.material?.[0]?.id !== 0 && parData.material?.length > 0,
    incidents: parData.incidents === "Nothing to Report." ? false : true,
    overnight: parData.startTime > parData.stopTime,
    subcontractor:
      parData.subcontractor?.[0]?.id !== 0 && parData.subcontractor?.length > 0,
    timeMaterials: Boolean(
      parData.matOsr?.length > 0 && parData.matJobsite?.length > 0
    ),
    verbalCounsel:
      parData.hrvc?.[0]?.counselNumber !== "0" && parData.hrvc?.length > 0,
  };

  return checkboxes;
};

export const parReducer = (parFormData, action) => {
  const newParFormData = JSON.parse(JSON.stringify(parFormData));
  const parFormCopy = { ...newParFormData.phaseCodes };
  const phaseCodeKeys = Object.keys(parFormCopy);
  const phaseCodeCopy = { ...newParFormData.phaseCodes };
  const phaseKeys = Object.keys(phaseCodeCopy);
  const previousPhases = { ...newParFormData.phaseCodes };

  switch (action.type) {
    case actionTypes.autofillPhases:
      return {
        ...newParFormData,
        phaseCodes: { ...previousPhases, ...action.autofillPhases },
      };

    case actionTypes.updateInputField:
      return {
        ...newParFormData,
        [action.payload.field]: action.payload.value,
      };

    case actionTypes.updateCheckbox:
      return {
        ...newParFormData,
        checkboxes: {
          ...newParFormData.checkboxes,
          [action.payload.field]: action.payload.value,
        },
      };

    case actionTypes.updatePhaseInput:
      const updatedFormData = { ...newParFormData };
      updatedFormData.phaseCodes[action.payload.phaseNum][
        action.payload.fieldName
      ] = action.payload.updateValue;

      return updatedFormData;

    case actionTypes.updatePhaseCodeField:
      const fieldList =
        newParFormData.phaseCodes[action.payload.phaseCode][
          action.payload.attributeName
        ];
      const updatedField = fieldList.find(
        (field) => field[action.payload.filterName] === action.payload.rowId
      );
      if (updatedField === undefined) return newParFormData;
      const newRecord = { ...updatedField };
      newRecord[action.payload.fieldName] = action.payload.updatedValue;

      const rowIndex = fieldList.findIndex(
        (field) => field[action.payload.filterName] === action.payload.rowId
      );
      const newFieldList = fieldList.filter(
        (field) => field[action.payload.filterName] !== action.payload.rowId
      );
      const newListData = [...newFieldList];
      newListData.splice(rowIndex, 0, newRecord);

      return {
        ...newParFormData,
        phaseCodes: {
          ...newParFormData.phaseCodes,
          [action.payload.phaseCode]: {
            ...newParFormData.phaseCodes[action.payload.phaseCode],
            [action.payload.attributeName]: newListData,
          },
        },
      };

    case actionTypes.updatePhaseCodeFieldObject:
      const fieldListObject =
        newParFormData.phaseCodes[action.payload.phaseCode][
          action.payload.attributeName
        ];
      const updatedFieldObject = fieldListObject.find(
        (field) => field[action.payload.filterName] === action.payload.rowId
      );
      if (updatedFieldObject === undefined) return newParFormData;
      const newRecordObject = { ...updatedFieldObject };
      newRecordObject[action.payload.fieldName][action.payload.objectKey] =
        action.payload.updatedValue;

      const rowIndexObject = fieldListObject.findIndex(
        (field) => field[action.payload.filterName] === action.payload.rowId
      );
      const newFieldListObject = fieldListObject.filter(
        (field) => field[action.payload.filterName] !== action.payload.rowId
      );
      const newListDataObject = [...newFieldListObject];
      newListDataObject.splice(rowIndexObject, 0, newRecordObject);

      return {
        ...newParFormData,
        phaseCodes: {
          ...newParFormData.phaseCodes,
          [action.payload.phaseCode]: {
            ...newParFormData.phaseCodes[action.payload.phaseCode],
            [action.payload.attributeName]: newListDataObject,
          },
        },
      };

    case actionTypes.updatePfs:
      return {
        ...newParFormData,
        pfs: action.payload.pfs,
      };

    case actionTypes.setWeatherInputs:
      return {
        ...newParFormData,
        temperatureHigh: action.payload.temperatureHigh,
        temperatureLow: action.payload.temperatureLow,
        wind: action.payload.wind,
        forecast: action.payload.forecast,
        precipitation: action.payload.precipitation,
      };

    case actionTypes.deleteIdb:
      const issues = [...newParFormData.idb];
      const issueData = issues?.filter(
        (iss) => iss?.issueNumber !== action.payload.idb
      );
      return {
        ...newParFormData,
        idb: issueData,
      };

    case actionTypes.addIdb:
      const newIssuesList = [
        ...newParFormData.idb,
        {
          issueNumber: uuid(),
          description: "",
          issueType: "",
          dateRaised: "",
          raisedBy: "",
        },
      ];
      return { ...newParFormData, idb: newIssuesList };

    case actionTypes.addDeprecatedIdb:
      const deprecatedIssueList = [
        {
          issueNumber: uuid(),
          description: action.payload,
          issueType: "Deprecated Issue Type",
          dateRaised: newParFormData.date,
          raisedBy: newParFormData.foreman,
        },
      ];

      return { ...newParFormData, idb: deprecatedIssueList };

    case actionTypes.updateIdb:
      const updatedValue = action.value;

      const issuePosition = [...newParFormData.idb].findIndex(
        (i) => i?.issueNumber === action.issueNumber
      );

      const issueFiltered = [...newParFormData.idb].filter(
        (i) => i?.issueNumber !== action.issueNumber
      );

      const newIssues = [...issueFiltered];

      newIssues.splice(issuePosition, 0, updatedValue);
      return { ...newParFormData, idb: newIssues };

    case actionTypes.deleteSubcontractor:
      const subcontractors = [...newParFormData.subcontractor];
      const subData = subcontractors.filter(
        (sub) => sub.id !== action.payload.subcontractor
      );
      return {
        ...newParFormData,
        subcontractor: subData,
      };

    case actionTypes.addSubcontractor:
      const newSubId = [...newParFormData.subcontractor].at(-1)?.id + 1 || 1;
      const newSubcontractorList = [
        ...newParFormData.subcontractor,
        {
          id: newSubId,
          name: "",
          description: "",
          dailyHours: "",
          employeeCount: "",
          type: action.subcontractorType,
          vendor: "",
        },
      ];
      return { ...newParFormData, subcontractor: newSubcontractorList };

    case actionTypes.updateSubcontractor:
      const search = [...newParFormData.subcontractor].find(
        (i) => i.id === action.id
      );
      search[action.updateField] = action.value;
      const position = [...newParFormData.subcontractor].findIndex(
        (i) => i.id === action.id
      );
      const filtered = [...newParFormData.subcontractor].filter(
        (i) => i.id !== action.id
      );
      const newSubContractors = [...filtered];
      newSubContractors.splice(position, 0, search);
      return { ...newParFormData, subcontractor: newSubContractors };

    case actionTypes.deleteMaterial:
      const materials = [...newParFormData.material];
      const matData = materials.filter(
        (mat) => mat.id !== action.payload.material
      );
      return {
        ...newParFormData,
        material: matData,
      };

    case actionTypes.addMaterial:
      const newMatId = [...newParFormData.material].at(-1)?.id + 1 || 1;

      const newMaterialList = [
        ...newParFormData.material,
        {
          id: newMatId,
          phaseCode: "",
          phaseDesc: "",
          description: "",
          units: "0.00",
          uom: "",
          cost: "0.00",
          total: "0.00",
        },
      ];

      return { ...newParFormData, material: newMaterialList };

    case actionTypes.updateMaterial:
      const matSearch = [...newParFormData.material].find(
        (i) => i.id === action.id
      );
      matSearch[action.updateField] = action.value;
      const matPosition = [...newParFormData.material].findIndex(
        (i) => i.id === action.id
      );
      const matFiltered = [...newParFormData.material].filter(
        (i) => i.id !== action.id
      );
      const newMaterials = [...matFiltered];
      newMaterials.splice(matPosition, 0, matSearch);
      return { ...newParFormData, material: newMaterials };

    case actionTypes.removePhaseCode:
      let phases = { ...newParFormData.phaseCodes };
      delete phases[action.payload.selectedPhase];
      return {
        ...newParFormData,
        phaseCodes: phases,
      };

    case actionTypes.addPhaseCode:
      const newPhase = {
        [action.payload.phase]: {
          phaseDescription: `${action.payload.phase} (${action.payload.description})`,
          laborData: [],
          equipmentData: [],
          progressEntry: [],
          phaseNote: "",
          CostTypes: action.payload.CostTypes || "",
        },
      };
      const newPhases = { ...newParFormData.phaseCodes, ...newPhase };

      const orderPhaseCodes = Object.keys(newPhases)
        .sort()
        .reduce((obj, key) => {
          obj[key] = newPhases[key];
          return obj;
        }, {});

      return { ...newParFormData, phaseCodes: orderPhaseCodes };

    case actionTypes.setPhaseCode:
      const phaseObj = {};
      for (let item of action.phaseCodes) {
        if (typeof item === "string") {
          let description = item.split(" ");
          const phaseCode = description[0];
          phaseObj[phaseCode] = {
            phaseDescription: `${phaseCode} (${description.slice(
              1,
              description.length
            )})`,
            laborData: [],
            equipmentData: [],
            progressEntry: [],
            phaseNote: "",
            CostTypes: action.costTypes?.[phaseCode] || "",
          };
          if (phaseCode === "012130-00-00") {
            phaseObj[phaseCode].CostTypes = "11";
          }
        } else {
          const phaseCode = item.Phase;
          phaseObj[phaseCode] = {
            phaseDescription: `${phaseCode} (${item.Description})`,
            laborData: [],
            equipmentData: [],
            progressEntry: [],
            phaseNote: "",
            CostTypes:
              item.CostTypes || (phaseCode === "012130-00-00" ? "11" : ""),
          };
        }
      }
      const combinedPhaseCodes = { ...newParFormData.phaseCode, ...phaseObj };
      return { ...newParFormData, phaseCodes: combinedPhaseCodes };

    case actionTypes.setParForm:
      return {
        ...action.form,
        checkboxes: action.form.checkboxes || initializeCheckboxes(action.form),
      };

    case actionTypes.addRecord:
      const recordList = [
        ...newParFormData.phaseCodes[action.payload.phaseCode][
          action.payload.attributeName
        ],
      ];
      action.payload.newRecords.forEach((record) => {
        recordList.push(record);
      });

      return {
        ...newParFormData,
        phaseCodes: {
          ...newParFormData.phaseCodes,
          [action.payload.phaseCode]: {
            ...newParFormData.phaseCodes[action.payload.phaseCode],
            [action.payload.attributeName]: recordList,
          },
        },
      };

    case actionTypes.addRecords:
      phaseKeys.forEach((phaseKey) => {
        const itemIds = [];

        phaseCodeCopy[phaseKey][action.attributeName].forEach((item) => {
          itemIds.push(
            action.attributeName === "laborData" ? item.id : item.equipNum
          );
        });

        action.payload.forEach((item) => {
          if (
            !itemIds.includes(
              action.attributeName === "laborData" ? item.id : item.equipNum
            )
          ) {
            phaseCodeCopy[phaseKey][action.attributeName].push(item);
          }
        });
      });
      return { ...newParFormData, phaseCodes: phaseCodeCopy };

    case actionTypes.removeRecord:
      if (
        Object.keys(newParFormData.phaseCodes).includes(
          action.payload.phaseCode
        )
      ) {
        const records = [
          ...newParFormData.phaseCodes[action.payload.phaseCode][
            action.payload.attributeName
          ],
        ];
        const newRecords = records.filter(
          (record) =>
            record[action.payload.filterName] !== action.payload.selectedRecord
        );

        return {
          ...newParFormData,
          phaseCodes: {
            ...newParFormData.phaseCodes,
            [action.payload.phaseCode]: {
              ...newParFormData.phaseCodes[action.payload.phaseCode],
              [action.payload.attributeName]: newRecords,
            },
          },
        };
      } else {
        return {
          ...newParFormData,
        };
      }

    case actionTypes.removeFromAllPhases:
      phaseCodeKeys.forEach((phaseCode) => {
        const filteredForm = parFormCopy[phaseCode][
          action.attributeName
        ].filter(
          (item) =>
            !action.payload.includes(
              action.attributeName === "laborData" ? item.id : item.equipNum
            )
        );
        parFormCopy[phaseCode][action.attributeName] = filteredForm;
      });

      return {
        ...newParFormData,
        phaseCodes: parFormCopy,
      };

    case actionTypes.clearPhasecode:
      return {
        ...newParFormData,
        phaseCodes: {
          ...newParFormData.phaseCodes,
          [action.payload.phasecode]: {
            ...newParFormData.phaseCodes[action.payload.phasecode],
            laborData: [],
            equipmentData: [],
            progressEntry: [],
            phaseNote: "",
            CostTypes:
              newParFormData.phaseCodes[action.payload.phasecode].CostTypes ||
              "",
          },
        },
      };

    case actionTypes.addHrvc:
      return {
        ...newParFormData,
        hrvc: [...newParFormData.hrvc, action.payload],
      };

    case actionTypes.editHrvc:
      const hrvcData = [...newParFormData.hrvc];

      const editHrvc = hrvcData.map((hrvcItem) => {
        if (hrvcItem.counselNumber === action.payload.counselNumber) {
          return action.payload;
        } else {
          return hrvcItem;
        }
      });

      return {
        ...newParFormData,
        hrvc: editHrvc,
      };

    case actionTypes.deleteHrvc:
      const newList = [...newParFormData.hrvc];
      const newData = newList.filter((item) => {
        return item.counselNumber !== action.payload.counselNumber;
      });

      return {
        ...newParFormData,
        hrvc: newData,
      };

    case actionTypes.initializeCheckboxes:
      return {
        ...newParFormData,
        checkboxes: initializeCheckboxes(action.payload),
      };

    default:
      throw new Error();
  }
};
